
import RadioButtonGroup from "@/components/FormBuilder/Components/RadioButtonGroup.vue";
import * as dateFns from "date-fns";
import Vue from "vue";
export default Vue.extend({
  components: { RadioButtonGroup },
  name: "loss-report-viewer",
  props: {
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      yesNoOptions: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ]
    };
  },
  methods: {
    getAge(dob: Date | null): string {
      return dob
        ? `${dateFns.differenceInYears(new Date(), new Date(dob))} years`
        : "";
    }
  }
});
